.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #5c7a3b;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #7eb151;
}
.button:active {
  background-color: #7eb151;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #5c7a3b;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 17px;
  font-family: Verdana, sans-serif;
  line-height: 1.41176471;
}
.unit caption {
  display: none;
}
.flag {
  background: #5c7a3b;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding: 5px 0;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #3b3d3f;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 20px;
  list-style-position: outside;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 56.640625%;
  position: relative;
}
.cb-layout2 #slides,
.cb-layout3 #slides {
  padding-bottom: 36.5234375%;
}
@media only screen and (max-width: 460px) {
  #slides {
    padding-bottom: 260px;
  }
  .cb-layout2 #slides,
  .cb-layout3 #slides {
    padding-bottom: 170px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #5c7a3b;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #5c7a3b;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: Verdana, sans-serif;
  line-height: 1.42857143;
  color: #3b3d3f;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #5c7a3b;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #5c7a3b;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #5c7a3b;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #7eb151;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #7eb151;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: Verdana, sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form input.file,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #f4f6f1;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form input.file.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form input.file {
  padding-top: 11px;
  padding-bottom: 11px;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 8px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  background: #f4f6f1;
  height: 40px;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #5c7a3b;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff;
  background-color: #7eb151;
}
.unit.form input.submit:active {
  background-color: #7eb151;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #3b3d3f;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #5c7a3b;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #7eb151;
}
.two-step-verification-container a:active {
  background-color: #7eb151;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table-layout-fixed .table td.init {
  width: 140px;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.21642764vw;
  }
  .table-layout-fixed .table td.init {
    width: 18.25293351vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  position: absolute;
  left: 0;
  top: 0;
  height: 75px;
  transition: height 250ms;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-size: 17px;
  font-family: Verdana, sans-serif;
  line-height: 1.41176471;
  color: #3b3d3f;
  overflow: hidden;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1196px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section,
.container {
  float: left;
  width: 100%;
  position: relative;
}
.section--north {
  background-color: #eceeed;
}
.section--south {
  background-color: #e7eae9;
}
.section--fareast {
  background-color: #f1f3f3;
}
.navbar {
  position: fixed;
  z-index: 2003;
  left: 0;
  top: 0;
  width: 100%;
}
.navbar .navbar-inner {
  position: relative;
  z-index: 1999;
  padding: 15px 0 5px;
  background-color: #dcdfde;
  transition: padding 250ms;
}
.navbar .desk {
  z-index: 5;
}
.navbar:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 25px;
  margin-top: -2px;
  border-style: solid;
  border-width: 0 0 45px 100vw;
  border-color: transparent transparent transparent #dcdfde;
  transform: scale(1.0001);
  transition: border 250ms, height 250ms;
}
.cb-scroll-triggered--active .navbar .navbar-inner {
  padding: 10px 0 10px;
}
.cb-scroll-triggered--active .navbar:after {
  height: 2px;
  border-width: 0 0 0 100vw;
}
.section--footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #dcdfde;
  font-size: 13px;
  line-height: 1.23076923;
  letter-spacing: 0.015em;
}
.section--footer h3 {
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 1.14285714;
  letter-spacing: 0.03em;
}
.section--footer a.tel {
  white-space: nowrap;
}
.section--footer .cols {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
}
.section--footer .cols .col {
  box-sizing: border-box;
}
.section--footer .vcard .phones,
.section--footer .vcard .vcardemail,
.section--footer .vcard .link {
  margin-top: 16px;
}
.section--footer .vcard .vcardemail {
  display: block;
}
.section--footer .navi {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.section--footer .navi > .item > .menu {
  box-sizing: border-box;
  padding-left: 15px;
  background: url(/images/icon-link-green.svg) no-repeat left center / 8px 8px;
}
.section--footer .navi > .item > .menu:hover,
.section--footer .navi > .item > .menu:focus {
  background-image: url(/images/icon-link-green-light.svg);
}
.section--footer .notfall {
  box-sizing: border-box;
  padding-left: 20px;
  background: url(/images/icon-notfall-green.svg) no-repeat left top 3px;
  background-size: 13px 13px;
  font-weight: bold;
}
.services {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin-left: -10px;
  font-size: 11px;
  line-height: 1.72727273;
}
.services .footlinks,
.services #services,
.services #social {
  box-sizing: border-box;
  padding: 0 10px;
}
#social {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
#social .meta {
  display: block;
  width: 28px;
  height: 28px;
  margin-right: 12px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_facebook {
  background: url(/images/icon-facebook.svg) no-repeat center;
}
#social .meta.service_twitter {
  background: url(/images/icon-twitter.svg) no-repeat center;
}
.corona {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1900;
  background-color: #dcdfde;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 8px rgba(120, 120, 120, 0.3);
  text-align: center;
  padding: 40px 0;
  font-size: 13px;
  line-height: 1.53846154;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 767px) {
  .corona {
    padding-top: 60px;
  }
}
.corona.is-hidden {
  transform: translateY(100%);
}
#edit .corona {
  display: none !important;
}
.corona__desk {
  float: left;
  width: 1000px;
  max-width: calc(100% - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .corona__desk {
    max-width: calc(100% - 20px);
  }
}
.corona__text {
  float: left;
  width: 100%;
}
.corona__text.corona__text--title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  color: #5c7a3b;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.corona__image {
  float: left;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}
.coronatoggle {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .coronatoggle {
    top: 30px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  .coronatoggle {
    right: 10px;
    top: 10px;
  }
}
.coronatoggle:before,
.coronatoggle:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  width: 100%;
  height: 5px;
  background-color: #5c7a3b;
}
.coronatoggle:after {
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}
a {
  color: #5c7a3b;
  text-decoration: none;
  transition: color 250ms, background 250ms, border 250ms;
}
a:hover,
a:focus {
  color: #7eb151;
}
h1,
h3 {
  color: #5c7a3b;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
h2 {
  font-weight: bold;
  text-transform: uppercase;
}
.skew {
  font-weight: bold;
}
.cb-layout1 .skew {
  color: #7eb151;
  font-weight: bold;
  text-transform: uppercase;
}
.area {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.area.fareast .seam {
  background-color: #fff;
}
.unit,
.unit .foot {
  box-sizing: border-box;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .fold-toggle {
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 10px 16px 10px 0;
  color: #758587;
  letter-spacing: 0.03em;
}
.unit.fold .fold-toggle:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 100%;
  background: url(/images/fold-toggle.svg) no-repeat right center / 16px 16px;
}
.unit.fold .fold-toggle.fold-toggle--open:after {
  transform: rotate(180deg);
}
.unit.fold .foot {
  display: none;
}
.unit.fold.pure {
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #e7ebe2;
}
.unit.fold.pure.fold--open {
  background-color: #f4f6f1;
}
.unit.fold.pure div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unit.fold.pure .part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 10px;
}
.unit.fold.pure div.more {
  border-top: 6px solid #fff;
}
.part {
  box-sizing: border-box;
}
div.link + .part.link,
div.load + .part.link,
div.link + .part.load,
div.load + .part.load {
  margin-top: 0;
}
div.link a.open,
div.load a.open,
div.link a.load,
div.load a.load {
  box-sizing: border-box;
  padding-left: 15px;
  background: url(/images/icon-link-green.svg) no-repeat left center / 8px 8px;
}
div.link a.open:hover,
div.load a.open:hover,
div.link a.load:hover,
div.load a.load:hover,
div.link a.open:focus,
div.load a.open:focus,
div.link a.load:focus,
div.load a.load:focus {
  background-image: url(/images/icon-link-green-light.svg);
}
.list--bullet li:before {
  content: '–';
}
@media (max-width: 1399px) {
  .navi {
    display: none;
  }
  #servicesNavi {
    display: none !important;
  }
  #servicesNaviPhone {
    display: block !important;
  }
}
.togglenavigation {
  box-sizing: border-box;
  position: fixed;
  top: 7px;
  right: 7px;
  z-index: 2001;
  width: 32px;
  height: 43px;
  padding: 10px 3px 13px;
  cursor: pointer;
}
.togglenavigation.cb-toggle-active {
  background-color: #fff;
}
@media (min-width: 768px) {
  .togglenavigation {
    top: 20px;
    right: 50px;
  }
}
.tlines {
  position: relative;
  width: 26px;
  height: 20px;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #6e7070;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 2000;
  max-height: calc(100vh - 50px);
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (min-width: 768px) {
  .mobile-navigation {
    max-width: 280px;
  }
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
}
@media (min-width: 768px) {
  .mobile-navigation {
    top: 63px;
    max-height: calc(100vh - 63px);
  }
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  transform: translateX(100px);
  transition: all 1.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transform: translateX(0);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.navigation {
  float: right;
}
#servicesNaviPhone {
  display: block;
  float: right;
  margin-right: 50px;
}
#servicesNaviPhone .meta {
  float: left;
  margin-left: 14px;
  display: block;
  width: 23px;
  height: 30px;
  background-size: 23px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-phone.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#servicesNaviPhone .meta:first-child {
  margin-left: 0;
}
#servicesNaviPhone .meta.service_anreise {
  background-image: url(/images/icon-map.svg);
  background-size: 20px 30px;
}
#servicesMobileNavigation {
  box-sizing: border-box;
  float: left;
  width: calc(100% - 40px);
  margin: 3px 20px 30px;
  border-top: 1px solid #3b3d3f;
  border-bottom: 1px solid #3b3d3f;
  padding: 3px 0;
}
#servicesMobileNavigation .meta {
  display: block;
  padding: 5px 0;
  color: #6e7070;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.02em;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 20px;
  margin: 20px 0 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  padding: 7px 0;
  padding-right: 26px;
  color: #6e7070;
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  background-color: #fff;
  color: #7eb151;
  font-weight: bold;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 3px;
  border-bottom: 1px solid #7eb151;
  padding-bottom: 3px;
}
.mobile-navigation div.sub2 > .item > .menu {
  padding: 5px 20px;
  font-size: 15px;
  line-height: 1.33333333;
}
.mobile-navigation div.sub2 > .item > .menu.path {
  padding-left: 35px;
  background: url(/images/navi-path.svg) no-repeat left 20px center / 8px 8px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.sub2 > .item {
  margin-top: -31px;
  opacity: 0;
  transition: margin 0.6s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000000000px;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  margin-top: 0;
  opacity: 1;
  transition: margin 0.6s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 26px;
  height: 31px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.desk {
  max-width: calc(100% - 20px);
}
@media (max-width: 1199px) {
  #home {
    overflow: hidden;
  }
  #home .logo {
    height: 75px;
  }
  .cb-scroll-triggered--active #home {
    height: 36px;
  }
}
#head {
  margin-top: 40px;
  margin-bottom: 20px;
}
.cb-layout2 #head,
.cb-layout3 #head {
  margin-bottom: 0;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    font-size: 14px;
    line-height: 1.35714286;
  }
}
.section--mood {
  margin-top: 50px;
}
.cb-layout1 .section--south {
  margin-top: 20px;
}
.cb-layout1 .section--footer {
  margin-top: 20px;
}
.section--footer .cols .col {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #7eb151;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  #social {
    order: 1;
    width: 100%;
    margin-bottom: 20px;
  }
  .footlinks,
  #services {
    order: 2;
    width: 50%;
  }
}
h1,
h3 {
  font-size: 21px;
  line-height: 1.42857143;
}
h2 {
  font-size: 19px;
  line-height: 1.31578947;
  letter-spacing: 0.01em;
}
.loud {
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.01em;
}
.skew {
  font-size: 19px;
  line-height: 1.31578947;
  letter-spacing: 0.01em;
}
.cb-layout1 .skew {
  font-size: 24px;
  line-height: 1.875;
  letter-spacing: 0.01em;
}
.area.base {
  width: 100%;
}
.area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.base .cb-album .body,
.area.base .head,
.area.base .foot,
.area.base .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.base .tiny {
  width: 50%;
}
.area.base > .slim {
  width: 50%;
}
.area.base > .slim .head,
.area.base > .slim .foot,
.area.base > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.base > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.base > .slim .tiny {
  width: 50%;
}
.cb-layout3 .area.main,
.cb-layout3 .area.side {
  width: 100%;
}
.cb-layout3 .area.main > .unit,
.cb-layout3 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .cb-album .body,
.cb-layout3 .area.side .cb-album .body,
.cb-layout3 .area.main .head,
.cb-layout3 .area.side .head,
.cb-layout3 .area.main .foot,
.cb-layout3 .area.side .foot,
.cb-layout3 .area.main .part,
.cb-layout3 .area.side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .tiny,
.cb-layout3 .area.side .tiny {
  width: 50%;
}
.cb-layout3 .area.main > .slim,
.cb-layout3 .area.side > .slim {
  width: 50%;
}
.cb-layout3 .area.main > .slim .head,
.cb-layout3 .area.side > .slim .head,
.cb-layout3 .area.main > .slim .foot,
.cb-layout3 .area.side > .slim .foot,
.cb-layout3 .area.main > .slim .part,
.cb-layout3 .area.side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim.cb-album .body,
.cb-layout3 .area.side > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim .tiny,
.cb-layout3 .area.side > .slim .tiny {
  width: 50%;
}
.area {
  margin-top: 20px;
  margin-bottom: 20px;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.cb-layout1 .area.base .unit,
.cb-layout2 .area.base .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout1 .area.base .unit .part,
.cb-layout2 .area.base .unit .part {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 5px;
}
.cb-layout1 .area.fareast,
.cb-layout2 .area.fareast {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout1 .area.fareast .unit,
.cb-layout2 .area.fareast .unit {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cb-layout1 .area.fareast .unit .part,
.cb-layout2 .area.fareast .unit .part {
  padding: 0 15px;
}
@media (max-width: 767px) {
  .cb-layout1 .area.north {
    margin-top: 0;
    margin-bottom: 0;
  }
  .cb-layout1 .area.north .unit {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .cb-layout1 .area.north .unit:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
    height: 1px;
    background-color: #5c7a3b;
  }
}
.cb-layout2 .area.side {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.cb-layout2 .area.side .unit .part {
  padding-left: 10px;
  padding-right: 10px;
}
.cb-layout2 .area.side .unit .part.tiny {
  width: 50%;
}
.cb-layout3 .area.main,
.cb-layout3 .area.side {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.cb-layout3 .area.main .unit .part,
.cb-layout3 .area.side .unit .part {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 5px;
}
.cb-layout2 .area.main,
.cb-layout3 .area.main {
  margin-top: 0;
}
@media (max-width: 767px) {
  .cb-layout2 .area.main,
  .cb-layout3 .area.main {
    margin-bottom: 0;
  }
}
.cb-layout3 .unit.fold {
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: calc(100% - 10px) !important;
}
.cb-layout3 .unit.fold.flat {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.unit.fold .fold-toggle {
  font-size: 18px;
  line-height: 1.5;
}
.unit.fold + .unit:not(.fold) {
  margin-top: 27px;
}
/*# sourceMappingURL=./screen-small.css.map */